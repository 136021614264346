import {relativeRoute} from "@/helpers/relativeRoute";
import {useRef} from "react";
import {useApp} from "@/Providers/AppProvider";
import logo from '@images/logo/logo.svg';
export type HeaderAuthProps = {
  user: {
    email: string,
    name: string,
    id: string,
    organisation?: {
      id: string
      name: string
    }
  },
  amount_persons: number,
  amount_invitations: number,
}

const Header = ({auth}: {
  auth?: HeaderAuthProps
}) => {

  const {onboarding} = useApp()
  const navRef = useRef<null | HTMLElement>(null)

  const handleToggle = () => {
    navRef.current?.classList.toggle('open')
  }

  const {user, amount_persons, amount_invitations} = auth ?? {};

  return <>

  <header className="bg-white flex justify-between navigation">
    <a href={relativeRoute('home')} className="header__logo" data-testid={'logo'}>
      <img src={logo} alt="Mensenlinq"/>
    </a>
    <nav ref={navRef}>
      <ul>
        <li><a href={relativeRoute('home')} data-testid={'home'}>Home</a></li>
        <li><a href={relativeRoute('obituary.index')} data-testid={'obituaries'}>Overlijdensberichten</a></li>
        {/*<li>*/}
        {/*  <a href={relativeRoute('herdenkingspagina')}>Herdenkingspagina</a>*/}
        {/*  <ul>*/}
        {/*    <li><a href={relativeRoute('herdenkingspagina-voorbeeld')}>Voorbeeld herdenkingspagina</a></li>*/}
        {/*    <li><a href={relativeRoute('herdenkingspagina-faq')}>Veelgestelde vragen herdenkingspagina</a></li>*/}
        {/*    <li><a href={relativeRoute('herdenkingspagina-stappenplan')}>Stappenplan herdenkingspagina</a></li>*/}
        {/*  </ul>*/}
        {/*</li>*/}
    <li><a href={relativeRoute('articles')} data-testid={'articles'}>Verhalen</a></li>
    <li><a href={relativeRoute('adressengids')} data-testid={'addresses'}>Adressengids</a></li>
    <li>
      <a href={relativeRoute('zakelijk')} data-testid={'business'}>Zakelijk</a>
      <ul>
        <li><a href={relativeRoute('online-condoleanceregister')} data-testid={'online-condolenceregister'}>Online condoleanceregister</a></li>
        {/*<li><a href={relativeRoute('herdenkingspagina-stappenplan')}  data-testid={'steps'}>Stappenplan herdenkingspagina</a></li>*/}
        <li><a href={relativeRoute('advertise')} data-testid={'advertise'}>Adverteren</a></li>
        <li><a href={relativeRoute('contact')} data-testid={'contact'}>Contact opnemen</a></li>
      </ul>
    </li>
        <li>
          <a
            href={onboarding ? relativeRoute(auth ? 'account.person.create' : 'create') : "https://www.familieberichten.nl"}
            target={onboarding ? '_self' : "_blank"}
            data-testid={'create'}>Overlijden bekendmaken</a>
          <ul>
            {/*<li><a href={relativeRoute('krant-online')}>Krant + online</a></li>*/}
            <li><a href={relativeRoute('standaard-rouwpagina')} data-testid={'standard-obituary'}>Standaard rouwpagina</a></li>
            <li><a href={relativeRoute('uitgebreide-rouwpagina')} data-testid={'extended-obituary'}>Uitgebreide rouwpagina</a></li>
          </ul>
        </li>
      </ul>
      <div className="btn btn__account">
        {auth
          ? <>
          <a href={relativeRoute('account')} title={'Jouw account'} data-testid={'account'}><i className="fa-regular fa-user"></i></a>
          <ul>
            <li><a href={relativeRoute('account')} data-testid={'account'}>Ingelogd als {user?.email}</a></li>
            {user?.organisation && <li className="border-b border-b-gray-400"><a href={relativeRoute('account')}
                                                               data-testid={'header-organisation'}>Organisatie: {user.organisation.name}</a></li>}
            <li><a href={relativeRoute('account')} data-testid={'persons'}>Rouwberichten ({amount_persons})</a></li>
            {amount_invitations && amount_invitations > 0 ? <li><a href={relativeRoute('invites.index')} data-testid={'invitations'}>Uitnodigingen ({amount_invitations})</a></li> : ''}
            <li className="border-b  border-b-gray-400"><a href={relativeRoute('profile.edit')} data-testid={'account_settings'}>Instellingen</a></li>
            <li><a href={relativeRoute('logout')} data-testid={'logout'}>Uitloggen</a></li>
          </ul>
          </>
          : <a href={relativeRoute('login')} title={'Inloggen'} data-testid={'login'}><i className="fa-regular fa-user"></i></a>
        }
  </div>
  <div className="btn btn__toggle" onClick={handleToggle}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</nav>
</header>

</>
}


export default Header
