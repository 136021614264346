


// Functie om een cookie te zetten
function setCookie(name: string, value: any) {

  const expirationDate = new Date();

  expirationDate.setFullYear(expirationDate.getFullYear() + 1);

  const expires = expirationDate.toUTCString();

  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}

// Functie om een cookie te lezen
function getCookie(name: string) {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [key, value] = cookie.split('=');
    if (key === name) return value;
  }
  return null;
}

// Gegevens opslaan in zowel een cookie als localStorage
export function saveData(key: string, value: any) {
  // Opslaan in localStorage
  localStorage.setItem(key, value);
  // Opslaan in een cookie
  setCookie(key, value);
}

// Gegevens ophalen uit localStorage of fallback naar cookie
export function loadData(key: string, defaultValue: any) {
  // Probeer eerst uit localStorage
  let storageValue = localStorage.getItem(key);
  const cookieValue = getCookie(key);

  if (!storageValue ) {
    localStorage.setItem(key, defaultValue);
  }

  if (!cookieValue) {
    setCookie(key, defaultValue);
  }

  return storageValue ?? cookieValue ?? defaultValue;
}

export function loadUniqueKey( key: string)
{
  let value = Math.floor(Math.random() * 99999999 + 1000000);

  const timestamp = new Date().getTime().toString();
  let persistentValue = timestamp + '_' + value


  let storageValue = localStorage.getItem(key);
  const cookieValue = getCookie(key);


  // Recover localStorage from cookie or create
  if (!storageValue ) {
    localStorage.setItem(key, cookieValue ?? persistentValue);
  }
// Recover cookie from localStorage or create
  if (!cookieValue) {
    setCookie(key, storageValue ?? persistentValue);
  }

  // one is changed. pick oldest.
  if (storageValue && cookieValue && storageValue !== cookieValue){
    // get timestamps from value
    const [storageTime] = storageValue.split('_')
    const [cookieTime] = cookieValue.split('_')

    if (storageTime < cookieTime) // cookie is older
    {
      localStorage.setItem(key, cookieValue);
      return cookieValue
    } else { // storage is older
      setCookie(key, storageValue);
      return storageValue
    }

  }
  return storageValue ?? cookieValue ?? persistentValue;
}