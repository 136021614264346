import {loadUniqueKey} from "@/helpers/persistantKey";


/**
 * Nationaal Media Onderzoek (ComScore/ScorecardResearch)
 */
export default function useNMO(
  {
    isHome,
    isLoggedIn,
  }: {
    isHome: boolean
    isLoggedIn: boolean
  }){

  // console.log('useNMO', isHome, isLoggedIn);


  const cs_fpid = loadUniqueKey('nmo_cs_fpid')

  const comScoreId: number = 16676137

  window._comscore = window._comscore || [];
  window._comscore.push({
    c1: 2,//Tag Type
    c2: comScoreId, //Comscore Publisher ID
    nmo_01: null,
    nmo_02: isHome ? '1' : '0',// 1 = homepage, 0 = rest
    nmo_10: isLoggedIn ? '1' : '0',// contains '1' when logged in, '0' if not.
    cs_fpid: cs_fpid, //1P identifier, Contains the pseudonymized 1P identifier value, which could be either a user_id, login (preferred), OpenID or a first-party cookie.
    ns_site: 'total',
  });
    (function() {
      const s = document.createElement("script"),
        el = document.getElementsByTagName("script")[0];
      s.async = true;
    s.src = `https://sb.scorecardresearch.com/cs/${comScoreId}/beacon.js`;
    el.parentNode?.insertBefore(s, el);
  })();

}
